// Table.js

import React, { useState, useMemo } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  IconButton,
  Card,
  CardContent,
  Popover,
  Button,
  Select,
  MenuItem,
  InputBase,
  Tooltip,
} from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import styles from "./Table.css";

// Existing Filter Components
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  users,
}) {
  const options = useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach((row) => optionsSet.add(row.values[id]));
    return [...optionsSet.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      onChange={(e) => setFilter(e.target.value || undefined)}
      style={{ width: "100%" }}
    >
      <option value="">All</option>
      {options.map((option, i) => {
        const user = users.find((u) => u._id === option);
        return (
          <option key={i} value={option}>
            {user ? user.fullName : "Unassigned"}
          </option>
        );
      })}
    </select>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`Search ${count} records...`}
      style={{ width: "100%" }}
    />
  );
}

export const DateRangeColumnFilter = ({
  column: { filterValue = [], setFilter },
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        type="date"
        value={filterValue[0] || ""}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? val : undefined, old[1]]);
        }}
        placeholder="From"
        style={{ marginBottom: "8px" }}
      />
      <input
        type="date"
        value={filterValue[1] || ""}
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? val : undefined]);
        }}
        placeholder="To"
      />
    </div>
  );
};

export default function Table({
  columns,
  data,
  origin,
  estadosPipeline,
  users,
  page,
  pageSize,
  total,
  onPageChange,
  onPageSizeChange,
  selectedProperties = new Set(),
  handleSelectRow,
  enableSelection = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);
  const [inputPage, setInputPage] = useState(page);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (
      value === "" ||
      (/^\d+$/.test(value) && Number(value) <= Math.ceil(total / pageSize))
    ) {
      setInputPage(value);
    }
  };

  const handleInputBlur = () => {
    const newPage = inputPage ? Number(inputPage) : 1;
    if (
      newPage >= 1 &&
      newPage <= Math.ceil(total / pageSize) &&
      newPage !== page
    ) {
      onPageChange(newPage);
    } else {
      setInputPage(page);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleInputBlur();
    }
  };

  const handleFilterClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setActiveColumn(column);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setActiveColumn(null);
  };

  const renderHeader = (column) => (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div {...column.getSortByToggleProps()} style={{ flex: "1" }}>
          {column.render("Header")}
          <span>
            {column.isSorted ? (
              column.isSortedDesc ? (
                <ArrowDownward fontSize="small" />
              ) : (
                <ArrowUpward fontSize="small" />
              )
            ) : (
              ""
            )}
          </span>
        </div>
        {column.canFilter && (
          <IconButton
            size="small"
            onClick={(e) => handleFilterClick(e, column.id)}
            aria-label={`Filter for ${column.id}`}
          >
            <FilterListIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <Popover
        id={`popover-${column.id}`}
        open={activeColumn === column.id}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            {column.canFilter ? column.render("Filter", { users }) : null}
          </CardContent>
        </Card>
      </Popover>
    </>
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const filterTypes = useMemo(
    () => ({
      between: (rows, id, filterValues) => {
        let [startDate, endDate] = filterValues;
        if (startDate && endDate) {
          startDate = new Date(startDate).getTime();
          endDate = new Date(endDate).getTime();
          return rows.filter((row) => {
            const rowDate = new Date(row.values[id]).getTime();
            return rowDate >= startDate && rowDate <= endDate;
          });
        }
        return rows;
      },
    }),
    []
  );

  // Conditionally add the "Selected" column
  const enhancedColumns = useMemo(() => {
    if (enableSelection) {
      return [
        {
          Header: "Seleccionado",
          accessor: "selected",
          disableFilters: true,
          Cell: ({ row }) => (
            <input
              type="checkbox"
              checked={selectedProperties.has(row.original._id)}
              onChange={(e) =>
                handleSelectRow(row.original._id, e.target.checked)
              }
              style={{
                cursor: "pointer",
                width: "15px",
                height: "15px",
                borderRadius: "50%",
              }}
              aria-label={`Select row ${row.original._id}`}
            />
          ),
        },
        ...columns,
      ];
    }
    return columns;
  }, [columns, enableSelection, selectedProperties, handleSelectRow]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: enhancedColumns,
      data,
      defaultColumn,
      filterTypes,
      manualPagination: true,
      pageCount: Math.ceil(total / pageSize),
    },
    useFilters,
    useSortBy
  );

  return (
    <div className="table-responsive">
      <table {...getTableProps()} className={`table ${styles.table}`}>
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={styles.th}
                  key={column.id}
                >
                  {column.canFilter
                    ? renderHeader(column)
                    : column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            let backgroundColor = "white";
            if (!row.original.seen && origin === "properties") {
              backgroundColor = "#FFD983";
            } else if (row.original.status) {
              const estado = estadosPipeline.find(
                (e) => e._id.toString() === row.original.status.toString()
              );
              backgroundColor = estado ? estado.color : "white";
            }

            return (
              <tr
                {...row.getRowProps({
                  style: {
                    backgroundColor,
                    textDecoration:
                      row.original.estado === "completado" &&
                      origin === "acciones-comerciales"
                        ? "line-through"
                        : "none",
                  },
                })}
                key={row.id}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="align-middle"
                    key={cell.column.id}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination Controls */}
      <div className={styles.pagination}>
        <Button
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
          variant="outlined"
          size="small"
        >
          Anterior
        </Button>
        <span className={styles.pageInfo}>
          Página <strong>{page}</strong> de{" "}
          <strong>{Math.ceil(total / pageSize)}</strong>
        </span>
        <Button
          onClick={() => onPageChange(page + 1)}
          disabled={page >= Math.ceil(total / pageSize)}
          variant="outlined"
          size="small"
        >
          Siguiente
        </Button>
        <span className={styles.gotoPage}>
          | Ir a la página:
          <InputBase
            type="number"
            min={1}
            max={Math.ceil(total / pageSize)}
            value={inputPage}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            className={styles.inputPageNumber}
          />
        </span>
        <Select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className={styles.pageSizeSelect}
          variant="outlined"
          size="small"
        >
          {[10, 25, 50, 100].map((size) => (
            <MenuItem key={size} value={size}>
              Ver {size}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
