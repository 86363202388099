// PropertyCard.js

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { getAccionesComercialesByPropertyId } from "../../../api/accionComercial";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import FlagIcon from "@material-ui/icons/Flag";
import DraftsIcon from "@material-ui/icons/Drafts";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  title: {
    fontWeight: 600,
  },
  address: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
  },
  icons: {
    marginLeft: theme.spacing(1),
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  detail: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
  },
  menuSection: {
    maxHeight: "200px",
    overflow: "auto",
  },
}));

const activityIcons = [
  { type: "llamada", icon: <PhoneInTalkIcon fontSize="small" /> },
  { type: "reunion", icon: <PeopleAltIcon fontSize="small" /> },
  { type: "tarea", icon: <QueryBuilderIcon fontSize="small" /> },
  { type: "plazo", icon: <FlagIcon fontSize="small" /> },
  { type: "correo", icon: <DraftsIcon fontSize="small" /> },
  { type: "comida", icon: <RestaurantIcon fontSize="small" /> },
];

function PropertyCard({ property, onAddActivity, usersGroup, refresh }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actions, setActions] = useState([]);
  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    if (property) {
      getAccionesComercialesByPropertyId(property._id)
        .then((res) => {
          if (res.data) {
            setActions(
              res.data.filter((action) => action.estado === "pendiente")
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching actions:", error);
        });
    }
  }, [property, refresh]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddActivity = (selectedAction = null) => {
    handleMenuClose();
    onAddActivity(property._id, selectedAction);
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          {property.title || "Sin Título"}
        </Typography>
        <div className={classes.address}>
          <Typography variant="body2">
            {property.addresses?.[0] || "Sin Dirección"}
          </Typography>
          {property.addresses?.length > 1 && (
            <Tooltip
              title={
                <div>
                  <strong>Posibles direcciones:</strong>
                  <ul>
                    {property.addresses.map((address, index) => (
                      <li key={index}>{address}</li>
                    ))}
                  </ul>
                </div>
              }
              arrow
            >
              <IconButton size="small" className={classes.icons}>
                <ReportProblemIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {/* Additional Property Details */}
        <Typography variant="body2" className={classes.detail}>
          Tipo: {property.type || "N/A"}
        </Typography>
        <Typography variant="body2" className={classes.detail}>
          Precio: ${property.price?.value?.toLocaleString() || "N/A"}
        </Typography>
        <Typography variant="body2" className={classes.detail}>
          Área: {property.area ? `${property.area} m²` : "N/A"}
        </Typography>
        <Typography variant="body2" className={classes.detail}>
          Habitaciones: {property.nhabitaciones || 0} | Baños:{" "}
          {property.nbaños || 0}
        </Typography>
        <div className={classes.actionButtons}>
          <IconButton
            aria-label="more"
            aria-controls="property-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="property-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <div className={classes.menuSection}>
              {actions.map((activity, index) => (
                <div key={index}>
                  <MenuItem
                    onClick={() => {
                      if (activity.idUsuario === user._id) {
                        handleAddActivity(activity);
                      }
                    }}
                    disabled={activity.idUsuario !== user._id}
                  >
                    <ListItemIcon>
                      {
                        activityIcons.find(
                          (icon) => icon.type === activity.tipo
                        )?.icon
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        activity.nombre.length > 20
                          ? `${activity.nombre.substring(0, 20)}...`
                          : activity.nombre
                      }
                      secondary={
                        activity.fechaFin
                          ? `Finaliza el: ${new Date(
                              activity.fechaFin
                            ).toLocaleDateString()}`
                          : ""
                      }
                    />
                  </MenuItem>
                  <hr />
                </div>
              ))}
            </div>
            <MenuItem onClick={() => handleAddActivity()}>
              <ListItemIcon>
                <NoteAddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Programa una acción comercial" />
            </MenuItem>
          </Menu>
        </div>
      </CardContent>
    </Card>
  );
}

export default React.memo(PropertyCard);
