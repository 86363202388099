import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import {
  getConfiguration,
  updateConfiguration,
} from "../../../../api/configuration";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export default function ConfigurationPage() {
  const [refresh, setRefresh] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  useEffect(() => {
    getConfiguration()
      .then((res) => {
        if (res.status === 200) {
          setConfiguration(res.data[0]);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get configurations.",
        });
      });
  }, [refresh]);

  const handleChange = (e, section, key) => {
    setConfiguration({
      ...configuration,
      [section]: {
        ...configuration[section],
        [key]: parseFloat(e.target.value),
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateConfiguration(configuration._id, configuration)
      .then((res) => {
        if (res.status === 200) {
          alertSuccess("Configuration updated successfully");
          setRefresh(!refresh);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Error updating configuration.",
        });
      });
  };

  const labelsText = {
    areaError: "areaError (valor porcetual)",
    areaErrorIfExact: "areaErrorIfExact (valor porcetual)",
    añoConstruccionError: "añoConstruccionError (valor en años)",
    priceError: "priceError (valor porcetual)",
  };

  if (!configuration) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card>
        <CardHeader title="Panel de Configuración"></CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <h3>Pesos de la detección de duplicados</h3>
            <br />
            <Grid container spacing={3}>
              {Object.keys(configuration.weights).map((key) => (
                <Grid item xs={6} md={4} key={key}>
                  <TextField
                    label={key}
                    type="number"
                    step="0.01"
                    value={configuration.weights[key]}
                    onChange={(e) => handleChange(e, "weights", key)}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
            <br />
            <br />
            <h3>Margen de error para la detección de direcciones</h3>
            <br />
            <Grid container spacing={3}>
              {Object.keys(configuration.errorFactors).map((key) => (
                <Grid item xs={6} md={3} key={key}>
                  <TextField
                    label={labelsText[key] || key}
                    type="number"
                    step="1"
                    value={configuration.errorFactors[key]}
                    onChange={(e) => handleChange(e, "errorFactors", key)}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="btn btn-primary"
              style={{ marginTop: "20px" }}
            >
              Guardar Configuración
            </Button>
          </form>
        </CardBody>
      </Card>
    </>
  );
}
