import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  makeStyles,
  Modal,
  Grid,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Typography,
  Box,
  CardContent,
  TableContainer,
  Table as MaterialTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Table as BootstrapTable } from "react-bootstrap";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { ToggleOff, ToggleOn } from "@material-ui/icons";
import { shallowEqual, useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { buttonsStyle } from "../../../components/tables/table";
import Table, {
  SelectColumnFilter,
} from "../../../components/customTable/Table";
import {
  getPropertiesByUserId,
  getProperties,
  getPropertiesByIds,
  deleteProperty,
  getGroupCadastralReferences,
  changeStatusProperty,
  updateProperty,
} from "../../../../api/property";
import { getLocalidades } from "../../../../api/localidad";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import FiltersCard from "../../../components/PropertyCard/FiltersCard";
import { getUsers } from "../../../../api/user";
import LateralWindow from "./LateralWindow";
import LateralWindowUser from "./LateralWindowUsers";
import ImageCarouselDialog from "../../../components/dialogs/ImageCarouselDialog/ImageCarouselDialog";
import { getEstadosPipeline } from "../../../../api/estadosPipeline";
import { exportPropertiesToExcel } from "../../../../utils/exportToExcel";

import "./Properties.css";
import {
  formatearNumero,
  getRefId,
  limpiarNumero,
} from "../../../../utils/helpers";
import { get } from "lodash";

const useStyles = makeStyles(() => ({
  dialogContent: {
    padding: 0,
    overflow: "hidden",
  },
  fancyLoader: {
    color: "#fe8613",
    animation: `$spin 1s linear infinite`,
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

let listIdsDupli = [];

function getData(properties, dupli) {
  let data = [];
  for (let i = 0; i < properties.length; ++i) {
    const elem = { ...properties[i], selected: false };
    elem.refId = getRefId(properties[i]._id);
    elem.title =
      properties[i].title === "undefined undefined"
        ? "---------"
        : properties[i].title;
    elem.potentialDuplicates = properties[i].potentialDuplicates
      ? properties[i].potentialDuplicates
      : [];
    if (!dupli) {
      listIdsDupli = Array.from(
        new Set(
          properties.flatMap((property) => property.potentialDuplicates || [])
        )
      );
    }
    elem.type = properties[i].type ? properties[i].type : "---------";
    elem.createdAt = properties[i].createdAt
      ? properties[i].createdAt
      : "---------";
    elem.price = properties[i].price ? properties[i].price : [];
    elem.price.forEach((price) => {
      price.value = formatearNumero(price.value);
    });
    elem.area = properties[i].area ? properties[i].area : 0;
    elem.nhabitaciones = properties[i].nhabitaciones
      ? properties[i].nhabitaciones
      : 0;
    elem.nbaños = properties[i].nbaños ? properties[i].nbaños : 0;
    elem.nplanta = properties[i].nplanta ? properties[i].nplanta : 0;
    elem.añoConstruccion = properties[i].añoConstruccion
      ? properties[i].añoConstruccion
      : 0;
    elem.status = properties[i].status ? properties[i].status : "---------";
    elem.image = properties[i].gallery ? properties[i].gallery : "---------";
    elem.addresses = properties[i].addresses
      ? properties[i].addresses
      : "---------";
    elem.id = properties[i]._id;
    elem.seen = properties[i].seen;
    elem.anunciante = properties[i].anunciante
      ? properties[i].anunciante
      : "---------";
    elem.active = properties[i].active;
    elem.possibleCadastralReferences =
      properties[i].possibleCadastralReferences;
    data = data.concat(elem);
  }
  if (!dupli) {
    listIdsDupli = listIdsDupli.filter(
      (id) => !data.some((property) => property._id === id)
    );
  }
  return data;
}

let propertiesDupli = [];

async function getPropDupli(listIdsDupli) {
  try {
    let response = await getPropertiesByIds(listIdsDupli);
    if (response.status === 200) {
      propertiesDupli = getData(response.data, true);
    }
  } catch (error) {
    alertError({
      error: error,
      customMessage: "Could not get properties.",
    });
  }
}

export default function PropertiesPage() {
  //Datos de la tabla
  const [data, setData] = useState([]);
  //Loading mientras no tengo los datos de la tabla
  const [loading, setLoading] = useState(true);
  //Estados del pipeline
  const [estadosPipeline, setEstadosPipeline] = useState([]);
  //Id de la propiedad
  const [propertyId, setPropertyId] = useState(null);
  //Dialogo de vista previa
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  //Imagen de vista previa
  const [previewImage, setPreviewImage] = useState(null);
  //Dialogo de confirmación
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  //Refrescar la tabla
  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [isLateralOpen, setIsLateralOpen] = useState(false);
  const [isLateralUsersOpen, setIsLateralUsersOpen] = useState(false);
  //Propiedad para la ventana lateral
  const [currentProperty, setCurrentProperty] = useState(null);
  //Propiedad para el popup de addresses de la propiedad
  const [addressProperty, setAddressProperty] = useState(null);
  //Propiedades duplicadas
  const [duplicates, setDuplicates] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState(new Set());
  //Cadastral References
  const [cadastralReferences, setCadastralReferences] = useState([]);
  const [selectedReference, setSelectedReference] = useState(null);
  const [openReferenceModal, setOpenReferenceModal] = useState(false);
  //Localidades
  const [localidades, setLocalidades] = useState([]);
  //SnackBar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({ active: true });
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const classes = useStyles();

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      try {
        let response;
        if (user.role === "admin") {
          response = await getProperties({
            page,
            limit: pageSize,
            filters,
            sortBy,
            order: sortOrder,
          });
        } else {
          response = await getPropertiesByUserId(user._id, {
            page,
            limit: pageSize,
            filters,
            sortBy,
            order: sortOrder,
          });
        }

        if (response.status === 200) {
          const properties = getData(response.data.data, false);
          console.log(properties);
          await getPropDupli(listIdsDupli);
          setData(properties);
          setTotal(response.data.total);
          setLoading(false);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get properties.",
        });
        setLoading(false);
      }
    };

    fetchProperties();
  }, [user, refresh, page, pageSize, filters]);

  useEffect(() => {
    setPage(1);
  }, [sortBy, sortOrder]);

  useEffect(() => {
    const fetchEstadosPipeline = async () => {
      try {
        const response = await getEstadosPipeline();
        if (response.status === 200) {
          setEstadosPipeline(response.data);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get estados pipeline.",
        });
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        if (response.status === 200) {
          setUsers(response.data);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get users.",
        });
      }
    };

    const fetchLocalidades = async () => {
      try {
        const response = await getLocalidades();
        if (response.status === 200) {
          console.log(response.data[0]);
          const filteredLocalidades = response.data.filter(
            (l) => l?.takeIntoAccount
          );
          console.log(filteredLocalidades.length);
          setLocalidades(filteredLocalidades);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get localidades.",
        });
      }
    };

    fetchEstadosPipeline();
    fetchUsers();
    fetchLocalidades();
  }, [user, refresh]);

  const saveAddressProperty = async () => {
    try {
      addressProperty.price.forEach((price) => {
        price.value = limpiarNumero(price.value);
      });
      console.log(addressProperty.price);
      const res = await updateProperty(addressProperty.id, addressProperty);
      if (res.status === 200) {
        setRefresh(true);
        setAddressProperty(null);
      }
    } catch (error) {
      alertError({
        error: error,
        customMessage: "Could not update property.",
      });
    }
  };

  //Render cadastral reference
  const renderCadastralReference = () => {
    if (!selectedReference) {
      return (
        <MuiCard>
          <MuiCardHeader title="Información del Catastro" />
          <CardContent>
            <Typography>
              No hay información cadastral de la dirección seleccionada
            </Typography>
          </CardContent>
        </MuiCard>
      );
    }

    return (
      <MuiCard>
        <MuiCardHeader title="Información del Catastro" />
        <CardContent>
          <Grid container spacing={3}>
            {Object.entries(selectedReference).map(([key, value]) => {
              if (
                typeof value === "object" &&
                value !== null &&
                !Array.isArray(value)
              ) {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <Box mb={2}>
                      <Typography variant="h6" gutterBottom>
                        {key}
                      </Typography>
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <Typography key={subKey}>
                          <strong>{subKey}:</strong>{" "}
                          {subValue.length > 0 ? subValue : "No disponible"}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <Typography>
                      <strong>{key}:</strong>{" "}
                      {value.length > 0 ? value : "No disponible"}
                    </Typography>
                  </Grid>
                );
              }
            })}
          </Grid>
        </CardContent>
      </MuiCard>
    );
  };

  //Find reference of address
  const findReference = (address) => {
    return cadastralReferences.find(
      (r) =>
        address.includes(r.primerNumeroPolicia) &&
        address.includes(r.segundoNumeroPolicia) &&
        address.includes(r.escalera) &&
        address.includes(r.planta) &&
        address.includes(r.puerta)
    );
  };

  //Función para formatear la imagen de la propiedad
  function imageFormatter(imageURL) {
    return imageURL && imageURL.length > 1 ? (
      <img
        src={imageURL[0]}
        alt="property"
        style={{ width: "75px", cursor: "pointer" }}
        onClick={() => {
          setPreviewImage(imageURL);
          setOpenPreviewDialog(true);
        }}
        title="Click to view images"
      />
    ) : (
      <Tooltip title="No images available">
        <img
          src="/media/dummie-house.jpg"
          alt="No images available"
          style={{ width: "75px", cursor: "default" }}
        />
      </Tooltip>
    );
  }

  //Función para añadir los botones con funcionalidades a la tabla
  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={(event) => {
              if (event.ctrlKey || event.metaKey) {
                window.open("/edit-property/" + cell, "_blank");
              } else {
                history.push("/edit-property/" + cell);
              }
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Disable" : "Enable"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setPropertyId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOff />
                ) : (
                  <ToggleOn style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setPropertyId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
        <Tooltip title="More Info">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              const item = data.find((item) => item._id === cell);
              window.open(item.origin, "_blank");
            }}
          >
            <OpenInNewIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Ventana lateral">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              const item = data.find((item) => item._id === cell);
              handleLateralOpen(data.find((item) => item._id === cell));
            }}
          >
            <MenuOpenIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  //Función para abrir la ventana lateral
  function handleLateralOpen(property) {
    setCurrentProperty(property);
    setIsLateralOpen(true);
  }

  //Función para cerrar la ventana lateral
  function handleLateralClose() {
    setIsLateralOpen(false);
  }

  //Función para cerrar la ventana lateral de usuarios
  function handleLateralUsersClose() {
    setIsLateralUsersOpen(false);
    setSelectedProperties(new Set());
  }
  //Función para seleccionar una fila de la tabla
  // Selection handlers
  const handleSelectRow = (propertyId, isSelected) => {
    setSelectedProperties((prev) => {
      const updated = new Set(prev);
      if (isSelected) {
        updated.add(propertyId);
      } else {
        updated.delete(propertyId);
      }
      return updated;
    });
  };

  const handleSelectAll = async () => {
    setLoading(true);
    try {
      let response;
      if (user.role === "admin") {
        response = await getProperties({ page: 1, limit: total, filters });
      } else {
        response = await getPropertiesByUserId(user._id, {
          page: 1,
          limit: total,
          filters,
        });
      }

      if (response.status === 200) {
        const allProperties = response.data.data;
        const allPropertyIds = allProperties.map((prop) => prop._id);
        setSelectedProperties(new Set(allPropertyIds));
        setLoading(false);
      }
    } catch (error) {
      alertError({
        error: error,
        customMessage: "Could not select all properties.",
      });
      setLoading(false);
    }
  };

  //Función para limpiar todos los filtros
  const clearAllFilters = () => {
    setFilters({});
    setSelectedProperties(new Set());
  };
  //Función para formatear las direcciones de la propiedad
  const addressesFormatter = (addresses, propertyId) => {
    const property = data.find((item) => item.id === propertyId);
    return (
      <div
        onClick={() => {
          setOpenModal(1);
          setAddressProperty(property);
          getGroupCadastralReferences(
            property.possibleCadastralReferences
          ).then((res) => {
            if (res.status === 200) {
              setCadastralReferences(res.data);
            }
          });
        }}
        style={{ cursor: "pointer", display: "flex", flexDirection: "column" }}
      >
        {addresses.slice(0, 3).map((address, index) => (
          <span key={index}>{address.slice(0, 25)}...</span>
        ))}
        {addresses.length > 3 && <span>...</span>}
      </div>
    );
  };

  //Función para formatear las propiedades duplicadas
  function duplicateFormatter(duplicateIds) {
    const properties = [
      ...data.filter((property) => duplicateIds.includes(property._id)),
      ...propertiesDupli.filter((property) =>
        duplicateIds.includes(property._id)
      ),
    ];

    return properties.length === 0 ? (
      <span>--------------</span>
    ) : (
      <div
        onClick={() => {
          setOpenModal(2);
          setDuplicates(properties);
        }}
      >
        {properties.map((property, index) => (
          <span key={index} style={{ cursor: "pointer" }}>
            {property.title.slice(0, 25)}...
          </span>
        ))}
      </div>
    );
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Duplicados",
        accessor: "potentialDuplicates",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => duplicateFormatter(value),
      },
      {
        Header: "Referencia",
        accessor: "refId",
        disableFilters: true,
        getHeaderProps: () => ({
          className: "custom-header",
        }),
        Cell: ({ value }) => (
          <span
            style={{
              display: "inline-block",
              flex: "1",
              whiteSpace: "wrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Nombre",
        accessor: "title",
        disableFilters: true,
        getHeaderProps: () => ({
          className: "custom-header",
        }),
        Cell: ({ value }) => (
          <span
            style={{
              display: "inline-block",
              flex: "1",
              whiteSpace: "wrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Galería",
        accessor: "image",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => imageFormatter(value),
      },
      {
        Header: "Tipo de Inmueble",
        accessor: "type",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Población",
        accessor: "localidad.fullName",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Precio",
        accessor: "price",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ value }) => {
          const sortedPrices = [...value].sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          const recentPrice = sortedPrices[0].value;
          const priceEvolution = sortedPrices
            .map(
              (price) =>
                `€${price.value} on ${new Date(
                  price.date
                ).toLocaleDateString()}`
            )
            .join("\n");
          return (
            <div title={priceEvolution} style={{ cursor: "pointer" }}>
              {recentPrice}
            </div>
          );
        },
      },
      {
        Header: "Superfície",
        accessor: "area",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
      },
      {
        Header: "Direcciones",
        accessor: "addresses",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value }, row: { original } }) =>
          addressesFormatter(value, original.id),
      },
      {
        Header: "Anunciante",
        accessor: "anunciante",
        filter: "includes",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Filter: SelectColumnFilter,
        Cell: ({ cell: { value } }) => <span>{value}</span>,
      },
      {
        Header: "Creado el",
        accessor: "createdAt",
        disableSortBy: true,
        filter: "between",
        cursor: "pointer",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => {
          const formattedDate = new Date(value).toLocaleString();
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "",
        accessor: "id",
        disableFilters: true,
        width: 200,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => buttonFormatter(value),
      },
    ];

    if (user?.role === "admin") {
      baseColumns.splice(1, 0, {
        Header: "Usuario Asignado",
        accessor: "assignedUser",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          return value && value.fullName ? value.fullName : "No asignado";
        },
      });
    }

    return baseColumns;
  }, [data, user, users]);

  useEffect(() => {
    console.log("Selected properties: ", selectedProperties);
  }, [selectedProperties]);

  // Function to handle Excel download
  const handleDownloadExcel = async () => {
    setLoading(true);
    try {
      let properties = [];

      if (selectedProperties.size > 0) {
        const selectedIds = Array.from(selectedProperties);
        const response = await getPropertiesByIds(selectedIds);

        if (response.status === 200) {
          properties = response.data;
        }
      } else {
        let response;
        if (user.role === "admin") {
          response = await getProperties({ page: 1, limit: total, filters });
        } else {
          response = await getPropertiesByUserId(user._id, {
            page: 1,
            limit: total,
            filters,
          });
        }

        if (response.status === 200) {
          properties = response.data.data;
        }
      }

      // Function to export to Excel
      exportPropertiesToExcel(properties);
      setLoading(false);
    } catch (error) {
      alertError({
        error: error,
        customMessage: "Could not download Excel.",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Properties list">
          <CardHeaderToolbar>
            <Button
              type="button"
              className="btn btn-primary mx-2"
              onClick={() => history.push("/edit-property")}
            >
              Añadir Nueva
            </Button>
            {user?.role === "admin" && (
              <Button
                variant="contained"
                className="btn btn-primary mx-2"
                disabled={selectedProperties.size === 0}
                onClick={() => {
                  setIsLateralUsersOpen(true);
                }}
              >
                Asignar
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              className="btn btn-secondary mx-2"
              disabled={selectedProperties.length === 0}
              onClick={() => handleDownloadExcel()}
            >
              Descargar Excel
            </Button>
          </CardHeaderToolbar>
          <section className="my-5">
            <FiltersCard
              setFilters={setFilters}
              localidades={localidades}
              users={users}
              handleSelectAll={handleSelectAll}
              handleClearFilters={clearAllFilters}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
          </section>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress className={classes.fancyLoader} />
            </div>
          ) : (
            <>
              {selectedProperties.length > 0 ? (
                <b>Propiedades seleccionadas: {selectedProperties.length}</b>
              ) : (
                <b></b>
              )}
              <Table
                data={data}
                columns={columns}
                origin={"properties"}
                estadosPipeline={estadosPipeline}
                users={users}
                page={page}
                pageSize={pageSize}
                total={total}
                onPageChange={setPage}
                onPageSizeChange={setPageSize}
                selectedProperties={selectedProperties}
                handleSelectRow={handleSelectRow}
                handleSelectAll={handleSelectAll}
                enableSelection={true}
              />
            </>
          )}
          {/* ADDRESSES MODAL */}
          <section className="d-flex justify-content-center">
            <Modal
              open={openModal === 1}
              onClose={() => {
                setOpenModal(null);
                saveAddressProperty();
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  width: "80%",
                  maxHeight: "80vh",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  overflowY: "auto",
                }}
              >
                <TableContainer component={Paper}>
                  <MaterialTable>
                    <TableHead>
                      <TableRow>
                        <TableCell>Dirección</TableCell>
                        <TableCell>
                          Superfície elementos constructivos
                        </TableCell>
                        <TableCell>Año de antigüedad</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addressProperty?.addresses.map((address, index) => {
                        const reference = findReference(address);
                        return (
                          <TableRow key={index}>
                            <TableCell>{address}</TableCell>
                            <TableCell>
                              {reference
                                ? reference.superficieElementosConstructivos
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {reference
                                ? reference.anoAntiguedadBienInmueble
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  const updatedAddresses = [
                                    ...addressProperty.addresses,
                                  ];
                                  updatedAddresses.splice(index, 1);

                                  const updatedPossibleReferences = cadastralReferences.filter(
                                    (r) => r !== reference
                                  );

                                  setCadastralReferences(
                                    updatedPossibleReferences
                                  );
                                  setAddressProperty({
                                    ...addressProperty,
                                    addresses: updatedAddresses,
                                    possibleCadastralReferences: updatedPossibleReferences.map(
                                      (r) => r.referenciaCatastral
                                    ),
                                  });
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>

                              {user?.role === "admin" && (
                                <IconButton
                                  onClick={() => {
                                    setSelectedReference(reference);
                                    setOpenReferenceModal(true);
                                  }}
                                >
                                  <OpenInNew />
                                </IconButton>
                              )}
                              <IconButton
                                onClick={() => {
                                  if (reference) {
                                    const { latitud, longitud } = reference;
                                    const url = `https://www.google.com/maps/search/?api=1&query=${latitud},${longitud}`;
                                    window.open(url, "_blank");
                                  }
                                }}
                              >
                                <i className="fas fa-map-marker-alt"></i>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </MaterialTable>
                </TableContainer>
              </div>
            </Modal>
            <Modal
              open={openReferenceModal}
              onClose={() => setOpenReferenceModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  maxHeight: "90vh",
                  overflowY: "auto",
                  width: "80%",
                  maxWidth: "1000px",
                  zIndex: 10,
                }}
              >
                {renderCadastralReference()}
              </div>
            </Modal>
          </section>

          {/* POTENTIAL DUPLICATES MODAL */}
          <Modal
            open={openModal === 2}
            onClose={() => setOpenModal(null)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 100,
            }}
          >
            <div
              style={{
                width: "80%",
                maxHeight: "80vh",
                overflowY: "auto",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <BootstrapTable striped bordered hover responsive>
                <thead className="thead-dark">
                  <tr>
                    <th>Referencia</th>
                    <th>Nombre</th>
                    <th>Superficie</th>
                    <th>nBaños</th>
                    <th>nHabitaciones</th>
                    <th>nPlanta</th>
                    <th>Año Construcción</th>
                    <th>Precio</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {duplicates.map((property, index) => (
                    <tr key={index}>
                      <td>{property.refId}</td>
                      <td>{property.title}</td>
                      <td>{property.area} m²</td>
                      <td>{property.nbaños}</td>
                      <td>{property.nhabitaciones}</td>
                      <td>{property.nplanta}</td>
                      <td>{property.añoConstruccion}</td>
                      <td>
                        {property.price[property.price.length - 1].value} €
                      </td>
                      <td>
                        <Tooltip title="Open in system new Tab">
                          <IconButton
                            onClick={() => {
                              window.open(
                                `/edit-property/${property.id}`,
                                "_blank"
                              );
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Open in Idealista">
                          <IconButton
                            onClick={() => {
                              window.open(`${property.origin}`, "_blank");
                            }}
                          >
                            <OpenInBrowserIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={property?.active ? "Disable" : "Enable"}
                        >
                          <Button
                            style={buttonsStyle}
                            size="small"
                            onClick={() => {
                              setPropertyId(property);
                              setOpenConfirmDialog(1);
                            }}
                          >
                            {property?.active ? (
                              <ToggleOff />
                            ) : (
                              <ToggleOn style={{ color: "red" }} />
                            )}
                          </Button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BootstrapTable>
            </div>
          </Modal>
          <ConfirmDialog
            title={`Are you sure you want to ${
              propertyId?.active ? "disable" : "enable"
            } this property?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusProperty(propertyId.id, !propertyId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    // postLog("properties-list", "change-status", user.fullName);
                    alertSuccess({
                      title: `${propertyId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `Property ${
                        propertyId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      propertyId?.active ? "disable" : "enable"
                    } property.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this property?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteProperty(propertyId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Property removed successfully.",
                    });
                    setRefresh(true);
                    // postLog("Properties", "delete", user.fullName);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove property.",
                  });
                });
            }}
          />
          <ImageCarouselDialog
            open={openPreviewDialog}
            setOpen={setOpenPreviewDialog}
            images={previewImage}
          />
          <LateralWindow
            isOpen={isLateralOpen}
            onClose={handleLateralClose}
            property={currentProperty}
            users={users}
          />
          <LateralWindowUser
            isOpen={isLateralUsersOpen}
            onClose={handleLateralUsersClose}
            properties={selectedProperties}
            users={users}
          />
        </CardBody>
      </Card>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
